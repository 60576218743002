<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col
        class="d-flex align-center justify-center justify-md-start align-md-start mt-8 mb-md-4"
      >
        <FlagTitle title="Por uma ATI continuada" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                O projeto do Cadastro pela Assessoria Técnica em Mariana se
                consolidou no decorrer dos quatro anos de vigência e tornou-se
                referência pelos princípios e metodologia construídos e
                executados com e para os atingidos e atingidas, o que supõe o
                respeito ao tempo e aos processos desses sujeitos centrais.
                Essas pessoas, vítimas do maior desastre-crime socioambiental do
                Brasil, tiveram suas vidas modificadas e, para participar das
                etapas do Cadastro, precisam redistribuir o tempo entre
                trabalho, família, atividades pessoais e uma série de reuniões
                convocadas também pelas empresas rés, na pessoa da Fundação
                Renova. Esse é um dos desafios para a garantia da agilidade do
                processo.
              </p>
              <p>
                Na realização dessas etapas, é imprescindível a participação dos
                atingidos e atingidas, pois, como dito, trata-se de uma
                metodologia participativa. Isto implica tempo e disponibilidade
                das pessoas, sendo preciso considerar as condições emocionais,
                de saúde, climáticas, etc. E, acrescenta-se, etapas como a
                tomada de termo, cartografia social e vistoria, em que as
                pessoas relatam o que vivenciaram com o desastre, muitas vezes
                precisam ser (re)agendadas mais de uma vez para a conclusão. O
                tempo de elaboração e disposição para rememorar precisa ser
                respeitado, posto que não bastasse todo o sofrimento imposto
                pelo rompimento e seus desdobramentos, lembrar e descrever o
                momento da tragédia, as perdas e os sentimentos é, novamente,
                reviver as dores e as violências do ocorrido. A Cáritas
                Brasileira Regional Minas Gerais entende, portanto, que esses
                processos devem ser tratados com o máximo de delicadeza e
                respeito às vítimas do rompimento.
              </p>
              <p>
                No cadastramento das atingidas e atingidos realizado pela
                Cáritas Brasileira, que atendeu cerca de 1500 núcleos familiares
                e entidades, totalizando mais de 5 mil atingidos e atingidas no
                município de Mariana, os danos acumulados individualmente
                manifestam, de forma comunitária, a perda das relações humanas e
                com a natureza, dos modos de vida, dos acessos, segurança, saúde
                física e mental, paisagem e recursos naturais, além das perdas
                no âmbito econômico, histórico e cultural.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img max-width="100%" src="../../assets/ati_01.png" alt="" />
          <p style="font-size: 0.5em">
            Reunião entre atingidos de Paracatu de Baixo, autoridades de Mariana
            e deputados federais que integram a Comissão externa da Câmara.
            Foto: Cáritas, 24 de março de 2022.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                Para além da estruturação do Cadastro em diversas etapas ₋ e
                portanto abordagens e linguagens distintas de modo a abarcar, da
                melhor forma, os danos e perdas da população atingida em
                Mariana. É importante ressaltar que o Cadastro aplicado em
                Mariana garantiu que o mesmo fosse organizado por núcleos
                familiares de forma que os atingidos e atingidas agrupam-se
                conforme o seu sentimento de pertencimento ao núcleo familiar
                para responder aos instrumentos, em contraponto ao Cadastro
                anterior que estava vinculado à propriedade. Nota-se também a
                observação do direito à autodeclaração daqueles que são
                cadastrados, uma vez que todos aqueles que se reconhecem como
                atingidas e atingidos têm resguardado o direito de participar do
                Cadastramento e definir a composição do núcleo familiar do qual
                acredita fazer parte.
              </p>
              <p>
                Ademais, o Cadastro apresentou-se como um grande desafio, pois o
                conceito de atingido, com o passar do tempo, tende a se alargar,
                e os efeitos negativos, em decorrência do desastre₋crime, na
                vida e nos direitos das pessoas, tendem a aumentar, fazendo com
                que um número maior de pessoas passe a se reconhecer atingidas e
                a pleitear seus direitos, o que também passa pelo devido
                levantamento de danos e perdas.
              </p>
              <p>
                Nesse contexto, vale, finalmente, pontuar sobre a interligação
                genealógica entre as diferentes frentes de atuação da Assessoria
                Técnica da Cáritas no território de Mariana relacionadas ao
                Cadastramento das pessoas atingidas e a posterior instituição do
                projeto de Assessoria Jurídica. Afinal, o Cadastro independente
                promovido pela Assessoria Técnica da Cáritas, grande conquista
                das populações atingidas nos termos do acordo anterior em 2016,
                foi colocado no TTAC de 2018 como sendo o parâmetro preferencial
                a ser utilizado pela Fundação Renova para formulação de proposta
                indenizatória. No entanto, o que infelizmente se percebe na
                prática é que o referido documento é reiteradamente
                negligenciado pela entidade quando da apresentação das propostas
                indenizatórias na Fase de Negociação Extrajudicial.
              </p>
              <p>
                Finalmente, lutar pela
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                  >reparação justa e integral</router-link
                >
                é o que as famílias das comunidades atingidas do município de
                Mariana têm feito desde o dia 05 de novembro de 2015. Decorridos
                todos esses anos após o desastre₋crime, porém, centenas de
                famílias das comunidades acompanhadas pela Cáritas ainda
                precisam lidar com diversas perdas causadas pelo rompimento da
                barragem de Fundão, o desencadeamento e perpetuação dos danos no
                decorrer do tempo e com a morosidade de efetivação de um
                processo reparatório. Portanto, uma das conclusões a que se pode
                chegar é a de que para um desastre₋crime socioambiental
                continuado, a assessoria técnica também tem que ser continuada.
              </p>
              <p style="text-align: center;">
                <strong
                  >Autoria: Laís Jabace Maia, Gabriela de Moura Pereira Câmara e
                  Trabalhadores da Cáritas<sup>1</sup></strong
                >
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img max-width="100%" src="../../assets/ati_02.png" alt="" />
          <p style="font-size: 0.5em">
            Audiência Pública dos atingidos com deputados que integram a
            Comissão Externa da Câmara e autoridades de Mariana, ocorrida em 24
            de março de 2022. Foto: Cáritas, 2022.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="container-fluid d-flex mt-5">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="inside__card" style="width: 100%;">
          <p style="margin-bottom: 0;">
            <sup>1</sup> JABACE, Laís; C MARA, Gabriela; Trabalhadores da
            Cáritas. “Cadastramento das Pessoas Atingidas em Mariana:
            autodeclaração para uma reparação justa”, In ALEIXO, L (org) “O
            direito das comunidades atingidas pela mineração à Assessoria
            Técnica Independente”. 2022. Representa um esforço coletivo de
            registro do processo de cadastro e assessoria jurídica realizados
            como parte da Assessoria Técnica aos Atingidos e Atingidas pelo
            Rompimento da Barragem do Fundão da Cáritas Brasileira Regional
            Minas Gerais, em Mariana-MG. Foram alguns momentos de discussão,
            escrita e revisão que, inclusive, nos surpreenderam pela efetiva
            construção coletiva do texto. Nesse sentido, são autores
            (organizados em ordem alfabética): Ana Maria de Oliveira Walter, Ana
            Paula Alves Ferreira, Ana Paula Santos Diniz, Anderson Augusto
            Ferreira Ventura, Celma Moreira da Silva, Gabriel Mateus Silva
            Leite, Gabriela de Moura Pereira Câmara, Gilda de Paula, Giovana
            Mendonça Galvão, Hudson Henrique Ferreira Faria, Isabela Barbosa
            Fernandes, Isabela Fabiana da Silva Ladeira, Jéssica de Paula Bueno
            da Silva, José Rafael Dias Dantas, Karine Lemos Gomes Ribeiro, Laís
            Jabace Maia, Laura Lélis Pascoal, Leandro Liberato dos Anjos, Marisa
            Versiani Elias, Paula Pfluger Zanardi, Pedro Paulo Barros Gonçalves,
            Thalita Araújo Silva, Thayná Elias Nunes e também tantos que
            passaram pelos dois projetos e aqueles que compõem as referidas
            equipes mas não estiveram presentes nas oficinas de escrita.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'projeto' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer o Projeto
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";
import FlagTitle from "../../components/FlagTitle.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    FlagTitle,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Por uma ATI e continuada",
          href: "sobre_ati_continuada",
        },
      ],
    };
  },
};
</script>

<style scoped>
h5 {
  color: #c92f05;
}

a {
  text-decoration: none;
}

.title__box {
  position: relative;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
